import { defineStore } from 'pinia';

interface IBreadcrumb {
    title: string,
    disabled: boolean,
    href: string
}

interface IBreadcrumbsState {
    items: IBreadcrumb[]
}

/** Хранилище хлебных крошек. */
export const useBreadcrumbsStore = defineStore({
    id: 'breadcrumbs',
    state: () : IBreadcrumbsState => ({
        items: []
    }),
    actions: {
        /** Создаёт список. */
        create(items: IBreadcrumb[]) {
            this.items = items;
        },

        /** Добавляет в список. */
        push(item: IBreadcrumb) {
            if(this.items.findIndex(i => i.href === item.href) < 0) {
                this.items.push(item);
            }
        },

        /** Возвращает предыдущий элемент. */
        pop() {
            /** Должно быть больше 1, потому что 1 это текущая страница */
            if(this.items.length > 1) {
                const item = this.items[this.items.length - 2]
                this.items.pop();
                return item;
            }
        }
    }
});